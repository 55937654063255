import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import( '../views/home/HomeView.vue')
  },
  {
    path: '/myIndex',
    name: 'myIndex',
    component: () => import( '../views/loginRegister/MyIndex.vue')
  },

  {
    path: '/forgetPass',
    name: 'forgetPass',
    component: () => import( '../views/loginRegister/ForgetPass.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/loginRegister/Register.vue')
  },
  {
    path: '/create',
    name: 'create',
    component: () => import( '../views/create/HomeIndex.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
